import React, { Fragment } from 'react';
import { Button, ExtendedTheme, Heading, TextBlock, Link } from 'neon-design-system';
import { useAuth0 } from 'neon-auth0';
import Template from '../components/Template/Template';
import ApplicationLogo from '../components/component-library/ProductInformation/ApplicationLogo/ApplicationLogo';
import { makeStyles, createStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { baseUrls } from '../helpers/urls';
import SplashScreenImage from '../components/component-library/Images/jpg/aside.jpg';
import { config } from "../config";
import SplashScreenFooter from '../components/component-library/Navigation/Footer/SplashScreenFooter';
import { containerWidth, largeContainerWidth } from '../constants/splash';

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
    content: {
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: containerWidth,
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: largeContainerWidth,
      },
    },
    header: {
      marginTop: theme.spacing(8),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(8),
      },
    },
    highlight: {
      color: theme.themeColors.primary[500],
    },
    subHeader: {
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
    landing: {
      marginLeft: theme.spacing(0),
    },
    buttonWrapper: {
      marginTop: theme.spacing(6),
      '& button': {
        marginRight: theme.spacing(1)
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(8),
      },
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(8),
      },
    },
    forgotLink: {
      marginTop: theme.spacing(2),
    },
    seperator: {
      width: theme.spacing(5),
      height: theme.spacing(0.125),
      background: theme.themeColors.grey[600],
      margin: theme.spacing(4, 0),
      [theme.breakpoints.up('sm')]: {
          width: theme.spacing(0.125),
          height: theme.spacing(5),
          margin: theme.spacing(0, 4),
      }
    }
  }));

const SplashScreen = () => {
    const classes = useStyles();
    const auth0Data = useAuth0();
    const history = useHistory();
    const customHeadingFromUri = (new URLSearchParams(window.location.search)).get('message');
    const heading = customHeadingFromUri || <Fragment>Trading, risk, market data, and insights for the global commodities markets.</Fragment>;
    const customTextFromUri = (new URLSearchParams(window.location.search)).get('text');
    const text = customTextFromUri || <Fragment>Providing access to unrivalled liquidity across both listed and OTC markets.<br />Complemented by our leading commentary and insights — all in one platform.</Fragment>;

    return (
        <Template
          content={
            <div className={classes.content}>
              <ApplicationLogo />
              <div className={classes.header}>
                <Heading xs="small" sm="medium" xl="large" styleName="largeTitle">{heading}</Heading>
              </div>
              <div className={classes.subHeader}>
                <TextBlock xs="small" sm="medium" xl="large" styleName="largeBody2" ink="muted">
                    {text}
                </TextBlock>
              </div>
              <div className={classes.buttonWrapper}>
                <Button
                  colorType="primary"
                  size="large"
                  onClick={() => {auth0Data.loginWithRedirect()}}>
                    Sign in
                </Button>
                {
                  config.isSignUpEnabled && (
                    <Button
                      colorType="grey"
                      size="large"
                      onClick={() => {history.push(baseUrls.signup)}}>
                      Create account
                    </Button>
                  )
                }
                <div className={classes.forgotLink}>
                  <Link to={baseUrls.forgot}>Forgot password?</Link>
                </div>
              </div>
            </div>
          }
          sideImage={SplashScreenImage}
          footer={<SplashScreenFooter />}
        />
      );
}

export default SplashScreen;
