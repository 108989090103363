import React from 'react';
import { MessageView, LargeWarningCircle, LargeCheckCircle } from 'neon-design-system';
import Template from "../components/Template/Template";
import SplashScreenImage from '../components/component-library/Images/jpg/aside.jpg';
import { Box } from '@material-ui/core';
import { useAuth0 } from "neon-auth0";
import { baseUrls } from '../helpers/urls';
import {useHistory, useLocation} from 'react-router-dom';
import { ExpiredLinkError } from '../components/ExpiredLinkError';
import { getUrlParams } from "../helpers/common";

export const PasswordUpdated = () => {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();
  const isSuccess = (new URLSearchParams(window.location.search)).get('success') === 'true';
  const callbackDescription = getUrlParams(location.search, 'message');
  const accessExpired  = callbackDescription?.includes('Access expired');

  if(accessExpired) return <ExpiredLinkError />;

  return (
      <Template
        content={
          <Box mt={8} width="100%">
            {isSuccess
              ? <MessageView
                  styleName="grey"
                  icon={<LargeCheckCircle/>}
                  title="Password updated"
                  children="Sign in to continue"
                  primaryAction={{
                    label: 'Sign In',
                    onClick: () => loginWithRedirect(),
                    styleName: 'primary'
                  }}
                />
              : <MessageView
                  styleName="grey"
                  icon={<LargeWarningCircle/>}
                  title="The link has expired"
                  children="If you still need to reset the password, please start again."
                  primaryAction={{
                    label: 'Reset password',
                    onClick: () => history.push(baseUrls.forgot),
                    styleName: 'primary'
                  }}
              />
            }
          </Box>
        }
        sideImage={SplashScreenImage}
      />
    );
};

export default PasswordUpdated;