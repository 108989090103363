import React, { useState, ChangeEvent, Fragment, useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ExtendedTheme, TextBlock, Heading, Link, TextField, Button, Checkbox, Snackbar, MessageView, LargeSend, ErrorText, InlineText } from 'neon-design-system';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Box, Grid  } from '@material-ui/core';
import ApplicationLogo from '../../components/component-library/ProductInformation/ApplicationLogo/ApplicationLogo';
import Template from "../../components/Template/Template";
import { baseUrls } from '../../helpers/urls';
import PolicyLinks from '../../components/component-library/Navigation/Footer/PolicyLinks';
import { useWebAuth } from '../../helpers/hooks/useWebAuth';
import { validateEmail } from "../../helpers/common";
import { config } from '../../config';
import { getReturnUrl, clearReturnUrlFromStorage } from '../../helpers/returnUrl';
import { useAuth0 } from "neon-auth0";
import PasswordStrength from "../../components/PasswordStrength/PasswordStrength";
import SplashScreenImage from "../../components/component-library/Images/jpg/aside.jpg";
import SimpleFooter from '../../components/component-library/Navigation/Footer/SimpleFooter';
import * as log from '../../helpers/logger';

interface Props {
  unactivatedAccount?: boolean;
}

interface Errors {
  email?: string;
  new?: string;
  confirm?: string;
  termsOfService?: string;
  privacyPolicy?: string;
}

const useStyles = makeStyles((theme: ExtendedTheme) => createStyles({
  root: {
    position: 'relative',
    maxWidth: theme.spacing(50),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(8),
    },
  },
  subHeader: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  signinLink: {
    fontSize: theme.customTypography.body.medium.fontSize,
    lineHeight: theme.customTypography.body.medium.lineHeight,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.customTypography.body.large.fontSize,
      lineHeight: theme.customTypography.body.large.lineHeight,
    },
    marginLeft: theme.spacing(0.5),
  },
  form: {
    marginTop: theme.spacing(6),
  },
  input: {
    marginTop: theme.spacing(2),
  },
  create: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: '100%',
    },
    height: theme.spacing(5),
  },
  policy: {
    marginTop: theme.spacing(3),
    justifyContent: 'left',
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      justifyContent: 'center',
    },
  },
  logo: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'left',
    },
  },
  agreementsContainer: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.75, 2),
    background: theme.themeColors.grey[800],
    borderRadius: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > div:not(:first-of-type)': {
      marginTop: theme.spacing(0.5)
    }
  },
  agreementsLink: {
    fontSize: theme.customTypography.body.small.fontSize,
    lineHeight: theme.customTypography.body.small.lineHeight,
    position: 'relative',
    top: theme.spacing(0.25),
    marginLeft: theme.spacing(-1.5)
  },
  agreementsError: {
    marginTop: theme.spacing(1),
  },
  messageOffset: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(70),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  messageResend: {
      textAlign: 'center'
  },
  passwordStrength: {
    marginTop: theme.spacing(0.5)
  }
}));

const Signup = ({ unactivatedAccount = false }: Props) => {
  const authClient = useWebAuth();
  const { loginWithRedirect } = useAuth0();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const classes = useStyles();
  const [acceptedTermsOfService, setAcceptedTermsOfService] = useState<boolean>(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(unactivatedAccount);
  const [success, setSuccess] = useState(false);
  const [isVerifyMessage, setVerifyMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>('');
  const [errors, setErrors] = useState<Errors>({});
  const [infoMessage, setInfoMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState({
    email: '',
    new: '',
    confirm: '',
  });

	const sendVerificationEmail = () => fetch(`${config.dashboardServer}/sentVerificationEmail`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
      email: form.email
    })
	}).then(r => r.json()).catch(e => {});

const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    aAd: false,
    special: false,
});

const passwordStrengthItems = [
  {
    message: '8 characters minimum',
    checked: passwordStrength.length,
  },
  {
    message: 'Lower case (a–z), upper case (A–Z) and numbers (0–9)',
    checked: passwordStrength.aAd,
  },
  {
    message: 'Special characters (!@#$%^&*)',
    checked: passwordStrength.special,
  }
];

const returnUrl = getReturnUrl();

const fieldChange = (field: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  setForm({
      ...form,
      [field]: e.target.value,
  });
  if(field === 'new') {
      checkPasswordStrength(e.target.value);
  }
};

useEffect(() => {
  if (submitted) {
    validate();
  }
}, [form, submitted, acceptedTermsOfService, acceptedPrivacyPolicy]);

const validate = () => {
    const newErrors: Errors = {};

    if (!validateEmail(form.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if(!form.email) {
        newErrors.email = 'Please enter an email address';
    }

    if(form.email.toLowerCase().includes('@marexspectron.com') || form.email.toLowerCase().includes('@marex.com')) {
      newErrors.email = 'Marex accounts can\'t be opened from the Neon Dashboard';
    }

    if(!form.new) {
        newErrors.new = 'Please enter a password';
    }
    else {
      if(!passwordStrength.aAd || !passwordStrength.length || !passwordStrength.special){
          newErrors.new = 'Password is not strong enough';
      }

      if(form.new !== form.confirm) {
          newErrors.confirm = 'Passwords do not match';
      }
    }

    if(!acceptedTermsOfService) {
      newErrors.termsOfService = 'Please agree to the Terms of Service';
    }
    if(!acceptedPrivacyPolicy) {
      newErrors.privacyPolicy = 'Please acknowledge that you have read the Privacy Notice';
    }
    setErrors(newErrors);
    return newErrors;
  }


const checkPasswordStrength = (password: string) => {
    const strength = {...passwordStrength};
    const aAd = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+/; // one lower, one upper, one number
    const special = /[!@#$%^&*()]+/;
    strength.length = password.length >= 8;
    strength.aAd = aAd.test(password);
    strength.special = special.test(password);
    setPasswordStrength(strength);
}

if(!authClient) return <></>;

  const createAccount = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSubmitted(true);
    log.info('User attempting to sign up to Neon', {email: form.email});

    var formValidateResult = validate();
    if(JSON.stringify(formValidateResult) !== '{}') {
      log.warn('User failed to sign up to Neon as as form is invalid', {email: form.email, errors: formValidateResult});
      return;
    }

    const userMetadata: any = {};
    if (executeRecaptcha) {
      userMetadata.token = await executeRecaptcha('sign_up');
    }
    if (returnUrl) {
      userMetadata.returnUrl = returnUrl;
      clearReturnUrlFromStorage();
    }

    log.info('User about to sign up to Neon', {email: form.email, userMetadata: userMetadata});

    authClient.signup({
      email: form.email,
      password: form.new,
      connection: 'Username-Password-Authentication',
      ...(JSON.stringify(userMetadata) !== '{}' ? {userMetadata} : {}),
    }, (e, r) => {
      if(e) {
        let formattedErrorMessage;
        let loggedFormattedErrorMessage;
        if (e.code === 'invalid_signup') {
          // user exists flow
          sendVerificationEmail().then(() => {
            setVerifyMessage(true);
          }).catch(e => {
            setErrorMessage('Something went wrong');
            console.error(e);
          });
        } else {
          if (e.code === 'extensibility_error') {
            // The following error code (e.description) comes from a custom Auth0 action that runs during the pre registration flow.
            if (e.description === 'AD_USER_CANNOT_REGISTER') {
              formattedErrorMessage = 'There is no need to register. Please sign in with your @marexspectron.com account.';
            } else if (e.description === 'REGISTRATION_BLOCKED_FROM_LOCATION') {
              const friendlyMessage = e?.original?.response?.body?.friendly_message;
              formattedErrorMessage = friendlyMessage;
            } else {
              formattedErrorMessage = 'Captcha error, please reload page and try again';
            }
              loggedFormattedErrorMessage = formattedErrorMessage;
            } else if (typeof e.description === 'string') {
              formattedErrorMessage = e.description;
              loggedFormattedErrorMessage = formattedErrorMessage;
            } else {
              formattedErrorMessage = `${e.name} ${e.policy || ''}`;
              loggedFormattedErrorMessage = formattedErrorMessage;
            }

            log.error('User failed to sign up to Neon as Auth0 returned errors', {email: form.email, userMetadata: userMetadata, formattedErrorMessage: loggedFormattedErrorMessage, error: e});
            setErrorMessage(formattedErrorMessage);
            return;
        }
      }
      if(r) {
        setShowMessage(true);
      }
    });
  };

  const verifyMessage = (
    <MessageView
      styleName="grey"
      icon={<LargeSend />}
      title="Please verify your email address"
      children={
        <>
          <div>We've just sent an email to {form.email}.</div>
          <div>Please click the link in it to complete the sign up process and activate your account.</div>
        </>}
    />
  );


  return (<Fragment>
    <Snackbar
        open={!!errorMessage}
        message={errorMessage}
        type="error"
        onClose={() => setErrorMessage('')}
        autoHideDuration={10000}
    />
    <Snackbar
        open={!!success}
        message={''}
        type="success"
        onClose={() => setSuccess(false)}
        autoHideDuration={3000}
    />
    <Snackbar
        open={!!infoMessage}
        message={infoMessage}
        type="info"
        onClose={() => setInfoMessage('')}
        autoHideDuration={3000}
    />
    <Template
      content={
            showMessage
              ? <div className={classes.messageOffset}>
                  {verifyMessage}
                  <Button size="large" colorType="primary" onClick={loginWithRedirect}>Sign in</Button>
                </div>
              : isVerifyMessage ? (
                <div className={classes.messageOffset}>
                  {verifyMessage}
                  <Box mb={4}>
                    <TextBlock ink="muted" styleName="body">Can't find the email?</TextBlock>
                  </Box>
                  <Button size="large" colorType="primary" onClick={sendVerificationEmail}>Resend the verification email</Button>
                </div>
              ) : <div className={classes.root}>
              <Grid>
                <ApplicationLogo logoClass={classes.logo} />
                <div className={classes.header}>
                    <Heading xs="small" sm="small" md="medium" lg="medium" xl="medium" styleName="largeTitle">Register an account</Heading>
                </div>
                <div className={classes.subHeader}>
                <TextBlock xs="small" sm="small" md="medium" lg="medium" xl="medium" styleName="largeBody2" ink="muted">Already registered?
                    <Link onClick={loginWithRedirect} className={classes.signinLink}>Sign in</Link>
                </TextBlock>
                </div>
                <form className={classes.form}>
                  <div className={classes.input}>
                      <TextField
                          id="email"
                          label="Email address"
                          placeholder=""
                          onChange={fieldChange('email')}
                          value={form.email}
                          error={!!errors.email}
                          errorText={errors.email}
                          size="large"
                      />
                  </div>
                  <div className={classes.input}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                onChange={fieldChange('new')}
                                value={form.new}
                                label="Your password"
                                type="password"
                                error={!!errors.new}
                                errorText={errors.new}
                                size="large"
                            />
                        </Grid>
                      <PasswordStrength className={classes.passwordStrength} items={passwordStrengthItems} />
                    </Grid>
                  </Grid>
                  </div>
                  <div className={classes.input}>
                      <TextField
                          id="repassword"
                          placeholder=""
                          onChange={fieldChange('confirm')}
                          value={form.confirm}
                          label="Confirm password"
                          type="password"
                          error={!!errors.confirm}
                          errorText={errors.confirm}
                          size="large"
                      />
                  </div>
                  <div className={classes.agreementsContainer}>
                    <div>
                      <Checkbox
                          id="termsOfService"
                          checked={acceptedTermsOfService}
                          value={acceptedTermsOfService ? "1" : "0"}
                          label="I agree to the Marex"
                          onChange={(event) => setAcceptedTermsOfService(event.target.checked)}
                      />
                      <Link
                        to={baseUrls.termsOfService}
                        ignoreRouter={true}
                        target="_blank"
                        className={classes.agreementsLink}
                      >
                        Terms of Service
                      </Link>
                    </div>
                    <div>
                      <Checkbox
                          id="privacyPolicy"
                          checked={acceptedPrivacyPolicy}
                          value={acceptedPrivacyPolicy ? "1" : "0"}
                          label="I have read the Marex"
                          onChange={(event) => setAcceptedPrivacyPolicy(event.target.checked)}
                      />
                      <Link
                        to={baseUrls.privacy}
                        ignoreRouter={true}
                        target="_blank"
                        className={classes.agreementsLink}
                      >
                        Privacy Notice
                      </Link>
                    </div>
                  </div>
                  {errors.termsOfService && <div className={classes.agreementsError}><ErrorText>{errors.termsOfService}</ErrorText></div>}
                  {errors.privacyPolicy && <div className={classes.agreementsError}><ErrorText>{errors.privacyPolicy}</ErrorText></div>}
                  <div>
                      <Button id="submit" name="submit" type="submit" colorType="primary" size="large" className={classes.create} onClick={createAccount}>
                          Create account
                      </Button>
                  </div>
                </form>
                <div>
                  <PolicyLinks type="signup" className={classes.policy}/>
                </div>
              </Grid>
            </div>
          }
      sideImage={SplashScreenImage}
      breakPointStartOnMD
      footer={showMessage ? undefined : <SimpleFooter />}
    />
  </Fragment>);
  };

const SignUpWithReCaptcha = () => (
  <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaKey}>
    <Signup />
  </GoogleReCaptchaProvider>
);

export default config.recaptchaKey ? SignUpWithReCaptcha : Signup;
