import React from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import { useAuth0 } from 'neon-auth0';
import { Button, LargeWarningCircle, LargeCheckCircle, MessageView } from 'neon-design-system';
import Template from './Template/Template';
import SideImage from '../components/component-library/Images/jpg/aside.jpg';
import { useLocation } from "react-router-dom";
import { getUrlParams } from "../helpers/common";
import { config } from "../config";
import { ErrorCard } from './Error'

const useStyles = makeStyles(theme => createStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(8),
		flexDirection: 'column',
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(70),
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	container: {
		paddingTop: theme.spacing(8),
		maxWidth: theme.spacing(87.5),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	applicationsGrid: {
		marginTop: theme.spacing(2),
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const EmailVerification = () => {
	const { loginWithRedirect } = useAuth0();
	const location = useLocation();
	const classes = useStyles();
	const message = getUrlParams(location.search, 'message');
	const expiredLink = message?.includes('Access expired');
	const title = expiredLink ? 'The link you clicked has expired' : 'Your account is now active';
	const description  = expiredLink ? 'You still need to confirm your email, please use the button below to sign in to send a new verification link.' : 'Please sign in to complete your profile and get started.';
	return (
		<Template
			content={
				<div className={[classes.container].filter(n => !!n).join(' ')}>
					<div className={classes.root}>
						<MessageView
							styleName="grey"
							icon={!expiredLink ? <LargeCheckCircle /> : <LargeWarningCircle />}
							title={title}
							children={description}
						/>
						<Button size="large" colorType="primary" onClick={loginWithRedirect}>Sign in</Button>
					</div>
					<Grid container className={classes.applicationsGrid}>
						<ErrorCard
							heading="Neon"
							imageUrl="/images/neon-logo.png"
							tel="+44 (0) 20 7655 6006"
							email="neonsupport@marex.com"
							link={config.baseUrl}
							linkMessage="Go to Neon"
							NDSAppLogoName="Neon" />
					</Grid>
				</div>
			}
			sideImage={SideImage}
		/>
	)
}

export default EmailVerification;
