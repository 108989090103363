import React, { Fragment, useEffect } from 'react';
import { makeStyles, createStyles, Grid } from '@material-ui/core';
import { config } from '../config';
import { MessageView, LargeWarningCircle } from 'neon-design-system';
import * as log from '../helpers/logger';
import Template from './Template/Template';
import SplashScreenImage from "../components/component-library/Images/jpg/aside.jpg";
import { useHistory, useLocation } from "react-router-dom";
import { getUrlParams } from "../helpers/common";
import { baseUrls} from "../helpers/urls";
import { ErrorCard } from "./Error";

const useStyles = makeStyles(theme => createStyles({
	container: {
		paddingTop: theme.spacing(8),
		maxWidth: theme.spacing(87.5),
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	applicationsGrid: {
		marginTop: theme.spacing(2),
		alignItems: 'center',
		justifyContent: 'center',
	},
	accessExpiredViewWrapper: {
		marginBottom: theme.spacing(6),
	},
}));

interface Action {
	label: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const ExpiredLinkError = () => {
	const location = useLocation();
	const { push } = useHistory();
	const callbackDescription = getUrlParams(location.search, 'error_description');
	const classes = useStyles();


	useEffect(() => {
		log.error('ExpiredLinkError', { error: callbackDescription });
	}, [callbackDescription]);

	return (
		<Template
			content={
				<Fragment>
					<div className={classes.container}>
						<div className={classes.accessExpiredViewWrapper}>
							<MessageView
								styleName="primary"
								icon={<LargeWarningCircle/>}
								size="large"
								title="The link you clicked has expired"
								primaryAction={
									{label: 'Send a new link', onClick: () => push(baseUrls.forgot)}
								}>
								If you still need to reset your password, please use the button below to get a new link.
							</MessageView>
						</div>
						<Grid container className={classes.applicationsGrid}>
							<ErrorCard
								heading="Neon"
								imageUrl="/images/neon-logo.png"
								tel="+44 (0) 20 7655 6006"
								email="neonsupport@marex.com"
								link={config.baseUrl}
								linkMessage="Go to Neon"
								NDSAppLogoName="Neon" />
						</Grid>
					</div>
				</Fragment>
			}
			sideImage={SplashScreenImage}
		/>
	);
};

export default ExpiredLinkError;
